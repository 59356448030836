<template>
  <label>
    <span v-if="label" :class="labelClasses">{{ label }}</span>
    <div :class="wrapperClasses">
      <textarea
        v-model="model"
        :rows="rows"
        :class="textareaClasses"
        :placeholder="placeholder"
      />
      <span v-if="$slots.footer" :class="footerClasses">
        <slot name="footer" />
      </span>
    </div>
  </label>
</template>

<script setup lang="ts">
import { useTextareaClasses } from "./composables/useTextareaClasses";
import type { Maybe } from "~/gql/graphql";

interface TextareaProps {
  modelValue?: string | undefined | Ref<string | undefined> | Maybe<string>;
  label?: string;
  rows?: number;
  custom?: boolean;
  placeholder?: string;
}

const props = withDefaults(defineProps<TextareaProps>(), {
  modelValue: "",
  label: "",
  rows: 4,
  custom: false,
  placeholder: "Write your message here...",
});

const emit = defineEmits(["update:modelValue"]);
const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const { textareaClasses, labelClasses, wrapperClasses, footerClasses } =
  useTextareaClasses(props.custom);
</script>
