import { simplifyTailwindClasses } from "@/utils/simplifyTailwindClasses";
import { computed } from "vue";

const textareaWrapperClasses =
  "block w-full border border-black-200 rounded-lg bg-gray-50";
const textareaDefaultClasses =
  "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-black-200 focus:ring-blue-500 focus:border-blue-500";
const textareaLabelClasses =
  "block mb-2 text-sm font-medium text-send-grey-100 font-send-default";
const textareaFooterClasses = "block py-2 px-3 border-black-200";

export function useTextareaClasses(custom: boolean) {
  const textareaClasses = computed(() =>
    simplifyTailwindClasses(
      textareaDefaultClasses,
      custom ? "bg-white border-none" : "border-black"
    )
  );

  const labelClasses = computed(() => textareaLabelClasses);
  const wrapperClasses = computed(() =>
    custom ? textareaWrapperClasses : `${textareaWrapperClasses} border-black`
  );
  const footerClasses = computed(() => textareaFooterClasses);

  return {
    textareaClasses,
    labelClasses,
    wrapperClasses,
    footerClasses,
  };
}
